import config from 'config'
import i18n from '@vue-storefront/i18n'

export default {
  title: 'AG | GrzejnikiDesign',
  titleTemplate: '%s - Sklep',
  htmlAttrs: {
    vmid: 'lang',
    lang: i18n.t('Pl-pl')
  },
  meta: [
    { charset: 'utf-8' },
    { vmid: 'description', name: 'description', content: 'Sklep internetowy AG oferuje grzejniki dekoracyjne do pokoju, łazienki i wiele innych. Grzejniki dekoracyjne żeliwne, retro i aluminiowe. Tel.223974392 Zadzwoń!' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1, minimal-ui, viewport-fit=cover' },
    { name: 'robots', vmid: 'robots', content: config.server.useNoIndexNoFollow ? 'noindex, nofollow' : 'index, follow' },
    { name: 'mobile-web-app-capable', content: 'yes' },
    { name: 'theme-color', content: '#ffffff' },
    { name: 'apple-mobile-web-app-status-bar-style', content: '#000' },
    { name: 'og:locale', property: 'og:locale', content: 'pl_PL' },
    { vmid: 'og:type', name: 'og:type', property: 'og:type', content: 'website' },
    { vmid: 'og:title', name: 'og:title', property: 'og:title', content: 'AG | GrzejnikiDesign - to największy wybór grzejników dekoracyjnych' },
    { vmid: 'og:description', name: 'og:description', property: 'og:description', content: 'AG | GrzejnikiDesign - to największy wybór grzejników dekoracyjnych łazienkowych dostępnych "od ręki" w rewelacyjnych cenach.' },
    { vmid: 'og:url', name: 'og:url', property: 'og:url', content: 'https://artykulygrzewcze.pl' },
    { vmid: 'og:site_name', name: 'og:site_name', property: 'og:site_name', content: 'AG | GrzejnikiDesign' },
    { vmid: 'og:image', name: 'og:image', property: 'og:image', content: 'https://artykulygrzewcze.pl/assets/home-page/Image12.png' }
  ],
  link: [
    { rel: 'icon', type: 'image/png', href: '/assets/favicon-32x32.png', sizes: '32x32' },
    { rel: 'icon', type: 'image/png', href: '/assets/favicon-16x16.png', sizes: '16x16' },
    { rel: 'apple-touch-icon', href: '/assets/apple-touch-icon.png' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_2048.png', sizes: '2048x2732' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_1668.png', sizes: '1668x2224' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_1536.png', sizes: '1536x2048' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_1125.png', sizes: '1125x2436' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_1242.png', sizes: '1242x2208' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_750.png', sizes: '750x1334' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_640.png', sizes: '640x1136' },
    { rel: 'manifest', href: '/assets/manifest.json' },
    { rel: 'preconnect', href: 'https://connect.facebook.net' },
    { rel: 'preconnect', href: 'https://www.facebook.com' },
    { rel: 'preconnect', href: 'https://fonts.gstatic.com' }
  ],
  script: [
    {
      src: 'https://cdn.jsdelivr.net/npm/pwacompat@2.0.17/pwacompat.min.js',
      async: true,
      crossorigin: 'anonymous'
    }
  ]
}
