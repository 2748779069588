<template>
  <div class="default-layout">
    <overlay v-if="overlayActive" />
    <loader />
    <div id="viewport" class="w-100 relative">
      <main-header />
      <async-sidebar
        :async-component="SearchPanel"
        :is-open="isSearchPanelOpen"
        @close="$store.commit('ui/setSearchpanel')"
      />
      <async-sidebar
        :async-component="Microcart"
        :is-open="isMicrocartOpen"
        @close="$store.commit('ui/setMicrocart')"
      />
      <async-sidebar
        :async-component="SidebarMenu"
        :is-open="isSidebarOpen"
        @close="$store.commit('ui/setSidebar')"
        direction="left"
      />
      <async-sidebar
        :async-component="Wishlist"
        :is-open="isWishlistOpen"
        @close="$store.commit('ui/setWishlist')"
      />
      <async-bottom-sidebar
        :async-component="ChooseVariation"
        :is-open="isAddToCartOpen"
        @close="$store.commit('ui/setAddToCart')"
      />
      <async-bottom-sidebar
        :async-component="AvailableVariation"
        :is-open="isAvailableVariationOpen"
        @close="$store.commit('ui/setAvailableVariation')"
      />
      <slot />
      <LazyHydrate when-visible>
        <main-footer />
      </LazyHydrate>
      <notification />
      <sign-up />
      <offline-badge />
      <order-confirmation :orders-data="ordersData" v-if="loadOrderConfirmation" />
    </div>
    <vue-progress-bar />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import Overlay from 'theme/components/core/Overlay.vue'
import Loader from 'theme/components/core/Loader.vue'
import OfflineBadge from 'theme/components/core/OfflineBadge.vue'
import { isServer } from '@vue-storefront/core/helpers'
import Head from 'theme/head'
import config from 'config'
import LazyHydrate from 'vue-lazy-hydration'

const AsyncBottomSidebar = () => import(/* webpackChunkName: "vsf-async-bottom-sidebar" */ 'theme/components/theme/blocks/AsyncBottomSidebar/AsyncBottomSidebar.vue')
const AsyncSidebar = () => import(/* webpackChunkName: "vsf-async-sidebar" */ 'theme/components/theme/blocks/AsyncSidebar/AsyncSidebar.vue')
const MainHeader = () => import(/* webpackChunkName: "vsf-header" */ 'theme/components/core/blocks/Header/Header.vue')
const MainFooter = () => import(/* webpackChunkName: "vsf-footer" */ 'theme/components/core/blocks/Footer/Footer.vue')
const SignUp = () => import(/* webpackChunkName: "vsf-sign-up" */ 'theme/components/core/blocks/Auth/SignUp.vue')
const Notification = () => import(/* webpackChunkName: "vsf-sidebar-menu" */ 'theme/components/core/Notification.vue')
const SidebarMenu = () => import(/* webpackPreload: true */ /* webpackChunkName: "vsf-sidebar-menu" */ 'theme/components/core/blocks/SidebarMenu/SidebarMenu.vue')
const Microcart = () => import(/* webpackPreload: true */ /* webpackChunkName: "vsf-microcart" */ 'theme/components/core/blocks/Microcart/Microcart.vue')
const Wishlist = () => import(/* webpackPreload: true */ /* webpackChunkName: "vsf-wishlist" */ 'theme/components/core/blocks/Wishlist/Wishlist.vue')
const SearchPanel = () => import(/* webpackChunkName: "vsf-search-panel" */ 'theme/components/core/blocks/SearchPanel/SearchPanel.vue')
const OrderConfirmation = () => import(/* webpackChunkName: "vsf-order-confirmation" */ 'theme/components/core/blocks/Checkout/OrderConfirmation.vue')
const AvailableVariation = () => import(/* webpackChunkName: "vsf-microcart" */ 'theme/components/core/AvailableVariation.vue')
const ChooseVariation = () => import(/* webpackChunkName: "vsf-microcart" */ 'theme/components/core/ChooseVariation.vue')

export default {
  data () {
    return {
      loadOrderConfirmation: false,
      ordersData: [],
      Microcart,
      Wishlist,
      SearchPanel,
      SidebarMenu,
      AvailableVariation,
      ChooseVariation
    }
  },
  mounted () {
    if (this.getCmsData) {
      this.getStylesFromMagento()
    }
  },
  watch: {
    getCmsData: function () {
      this.getStylesFromMagento()
    }
  },
  computed: {
    ...mapState({
      overlayActive: state => state.ui.overlay,
      isSearchPanelOpen: state => state.ui.searchpanel,
      isSidebarOpen: state => state.ui.sidebar,
      isAddToCartOpen: state => state.ui.addToCart,
      isMicrocartOpen: state => state.ui.microcart,
      isWishlistOpen: state => state.ui.wishlist,
      isAvailableVariationOpen: state => state.ui.availableVariation
    }),
    getCmsData () {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`]('custom-css-magento')
    }
  },
  methods: {
    getStylesFromMagento () {
      if (this.getCmsData && this.getCmsData.content) {
        let css = null
        let head = document.head || document.getElementsByTagName('head')[0]
        let styleTag = document.createElement('style');
        head.appendChild(styleTag)
        css = this.getCmsData.content.replace(/<\/?p>/g, '')
        if (styleTag.styleSheet) {
          // This is required for IE8 and below.
          styleTag.styleSheet.cssText = css
        } else {
          styleTag.appendChild(document.createTextNode(css))
        }
      }
    },
    fetchCmsBlock () {
      this.$store.dispatch('cmsBlock/list', {
        filterValues: null
      })
    },
    onOrderConfirmation (payload) {
      this.loadOrderConfirmation = true
      this.ordersData = payload
      EventBus.$emit('modal-show', 'modal-order-confirmation')
    },
    fetchMenuData () {
      return this.$store.dispatch('category/list', { level: config.entities.category.categoriesDynamicPrefetch && config.entities.category.categoriesDynamicPrefetchLevel ? config.entities.category.categoriesDynamicPrefetchLevel : null, includeFields: config.entities.optimize && isServer ? config.entities.category.includeFields : null, skipCache: isServer })
    }
  },
  serverPrefetch () {
    this.fetchCmsBlock()
    return this.fetchMenuData()
  },
  created () {
    if (!isServer) {
      this.fetchCmsBlock()
    }
  },
  beforeMount () {
    // Progress bar on top of the page
    this.$router.beforeEach((to, from, next) => {
      this.$Progress.start()
      this.$Progress.increase(40)
      next()
    })
    this.$router.afterEach((to, from) => {
      this.$Progress.finish()
    })
    EventBus.$on('offline-order-confirmation', this.onOrderConfirmation)
  },
  beforeDestroy () {
    EventBus.$off('offline-order-confirmation', this.onOrderConfirmation)
  },
  metaInfo: Head,
  components: {
    LazyHydrate,
    MainHeader,
    MainFooter,
    SidebarMenu, // eslint-disable-line vue/no-unused-components
    Overlay,
    Loader,
    Notification,
    SignUp,
    OfflineBadge,
    OrderConfirmation,
    AsyncSidebar,
    AsyncBottomSidebar
  }
}
</script>

<style lang="scss" src="theme/css/main.scss"></style>
